import React from "react";
import {
  Box,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import FlexBetween from "../../FlexBetween";
import { useTranslation } from 'react-i18next';
import {
    CustomDateTimePicker,
    CustomDivider,
    CustomSnackbar,
    DateTimeToggleButton,
    FileSelector,
    FileToggleButton,
    InputField,
    NumericInputField,
    PostButton,
    CustomDropDown
} from "../ui-elements";

const HarvestEventForm = ({
  harvestName,
  weight,
  smell,
  liquidity,
  homogeneity,
  onEventDetailsChange,
  onHarvestNameChange,
  onWeightChange,
  onSmellChange,
  onLiquidityChange,
  onHomogeneityChange,
  isFile,
  onFileChange,
  file,
  setIsFile,
  showDateTimePicker,
  selectedDateTime,
  onDateTimeChange,
  dateTimeError,
  onToggleDateTimePicker,
  handleEvent,
  snackbarOpen,
  handleCloseSnackbar,
  snackbarMessage,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const inputFieldStyle = {
    marginRight: "1rem",
    width: "50%",
    backgroundColor: colors.grey[300],
    color: colors.grey[900],
    borderRadius: "1rem",
    padding: "0.5rem 0.5rem",
  };

  return (
    <Box>
      {/* Harvest Name */}
      <Box my={2}>
        <InputField
        placeholder={t('harvestEventForm.harvestPlaceholder')}
        onChange={onHarvestNameChange}
        value={harvestName}
        label={t("harvestEventForm.description")}
        />
      </Box>

      {/* Weight Fields */}
      <Box display="flex" gap="0.7rem" my={2}>
      <NumericInputField
          label={t('harvestEventForm.weightLabel')}
          placeholder={t('harvestEventForm.weightPlaceholder')}
          value={weight}
          onChange={onWeightChange}
          sx={inputFieldStyle}
        />
      </Box>

      {/* Smell Dropdown Fields */}
      <Box display="flex" gap="0.7rem" my={3}>
      <CustomDropDown
          dropDownKey="smellDropDown"
          value={smell}
          onChange={(value, description) => {
            onSmellChange(value, description);
          }}
          sx={inputFieldStyle}

        />

      {/* Liquidity Description Dropdown Fields */}
      <CustomDropDown
          dropDownKey="liquidityDropDown"
          value={liquidity}
          onChange={(value, description) => {
            onLiquidityChange(value, description);
          }}
          sx={inputFieldStyle}
        />

      {/* Homogeneity Dropdown Fields */}
      <CustomDropDown
          dropDownKey="homogeneityDropDown"
          value={homogeneity}
          onChange={(value, description) => {
            onHomogeneityChange(value, description);
          }}
          sx={inputFieldStyle}
        />
        </Box>

      {/* Conditional Rendering for DateTime Picker */}
      <CustomDateTimePicker
        dateTimeLabel={t('harvestEventForm.dateTimeLabel')}
        showDateTimePicker={showDateTimePicker}
        selectedDateTime={selectedDateTime}
        onDateTimeChange={onDateTimeChange}
        dateTimeError={dateTimeError}
      />
      {/* Conditional Rendering for File Selector */}
      <FileSelector
        isFile={isFile}
        onFileChange={onFileChange}
        file={file}
        setIsFile={setIsFile}
      />

      {/* Divider */}
      <CustomDivider />

      {/* Buttons */}
      <FlexBetween>
        {/* File Button */}
        <FileToggleButton isFile={isFile} setIsFile={setIsFile} />
        {/* Toggle Button for DateTimePicker */}
        <DateTimeToggleButton
          showDateTimePicker={showDateTimePicker}
          onToggleDateTimePicker={onToggleDateTimePicker}
        />

        {/* Post Button */}
        <PostButton
          disabled={!harvestName}
          onClick={handleEvent}
          buttonText={t('harvestEventForm.postButton')}
        />
      </FlexBetween>

      {/* Snackbar */}
      <CustomSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Box>
  );
};

export default HarvestEventForm;
