import { tokens } from "../../../theme";
import { Box, InputBase, Typography } from "@mui/material";
import { useTheme } from "@mui/material";


const NumericInputField = ({ label, placeholder, value, onChange, sx }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ width: "100%" }}>
      {label && (
        <Typography
          sx={{
            marginBottom: "0.5rem",
            color: colors.grey[200],
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {label}
        </Typography>
      )}
      <InputBase
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type="number"
        sx={{
          width: "100%", // Ensures the input expands to the full width of the container
          padding: "0.8rem 1rem", // Adjust padding for numeric input
          fontSize: "16px", // Adjust font size for better readability
          borderRadius: "4px",
          border: "1px solid #ccc", // Optional: Add a border for better visibility
          ...sx, // Merge with custom styles passed via the `sx` prop
        }}
      />
    </Box>
  );
};

export default NumericInputField;
