import { tokens } from "../../../theme";
import { Box, InputBase, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const InputField = ({ label, placeholder, onChange, value }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <Box>
        {label && (
          <Typography
            sx={{
              marginBottom: "0.5rem",
              color: colors.grey[200],
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {label}
          </Typography>
        )}
        <InputBase
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          sx={{
            width: "100%",
            backgroundColor: colors.grey[300],
            color: colors.grey[900],
            borderRadius: "2px",
            border: "1px solid #ccc", // Optional: Add a border for better visibility
            padding: "1rem 2rem",
            fontSize: "16px",
          }}
        />
      </Box>
    );
  };

export default InputField;
