import React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import FlexBetween from "../../FlexBetween";
import { useTranslation } from "react-i18next";
import {
  CustomDateTimePicker,
  CustomDivider,
  CustomSnackbar,
  DateTimeToggleButton,
  FileSelector,
  FileToggleButton,
  InputField,
  NumericInputField,
  PostButton,
  CustomTagsManager,
} from "../ui-elements";

const FeedEventForm = ({
  feedName,
  weight,
  recirculated,
  onEventDetailsChange,
  onFeedNameChange,
  onWeightChange,
  onRecirculatedChange,
  isFile,
  onFileChange,
  file,
  setIsFile,
  showDateTimePicker,
  selectedDateTime,
  onDateTimeChange,
  dateTimeError,
  onToggleDateTimePicker,
  handleEvent,
  snackbarOpen,
  handleCloseSnackbar,
  snackbarMessage,
  tags,
  setTags,
  englishTags,
  setEnglishTags,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const inputFieldStyle = {
    marginRight: "1rem",
    width: "50%",
    backgroundColor: colors.grey[300],
    color: colors.grey[900],
    borderRadius: "1rem",
    padding: "0.5rem 0.5rem",
    fontSize: '16px',
  };

  return (
    <Box>
      {/* Feed Name */}
      <Box my={2}>
        <InputField
          placeholder={t("feedEventForm.feedPlaceholder")}
          onChange={onFeedNameChange}
          value={feedName}
          label={t("feedEventForm.description")}
        />
      </Box>

      {/* Weight and Recirculated Fields */}
      <Box display="flex" gap="0.7rem" my={2}>
        <NumericInputField
          label={t("feedEventForm.weightLabel")}
          placeholder={t("feedEventForm.weightPlaceholder")}
          value={weight}
          onChange={onWeightChange}
          sx={inputFieldStyle}
        />
        <NumericInputField
          label={t("feedEventForm.recirculatedLabel")}
          placeholder={t("feedEventForm.recirculatedPlaceholder")}
          value={recirculated}
          onChange={onRecirculatedChange}
          sx={{ ...inputFieldStyle, marginRight: 0 }}
        />
      </Box>

      {/* Conditional Rendering for DateTime Picker */}
      <CustomDateTimePicker
        dateTimeLabel={t("feedEventForm.dateTimeLabel")}
        showDateTimePicker={showDateTimePicker}
        selectedDateTime={selectedDateTime}
        onDateTimeChange={onDateTimeChange}
        dateTimeError={dateTimeError}
      />
      {/* Conditional Rendering for File Selector */}
      <FileSelector
        isFile={isFile}
        onFileChange={onFileChange}
        file={file}
        setIsFile={setIsFile}
      />

      {/* Divider */}
      <CustomDivider />

      {/* Buttons */}
      <FlexBetween>
        {/* File Button */}
        <FileToggleButton isFile={isFile} setIsFile={setIsFile} />
        {/* Toggle Button for DateTimePicker */}
        <DateTimeToggleButton
          showDateTimePicker={showDateTimePicker}
          onToggleDateTimePicker={onToggleDateTimePicker}
        />

        {/* Post Button */}
        <PostButton
          disabled={!feedName}
          onClick={handleEvent}
          buttonText={t("feedEventForm.postButton")}
        />
      </FlexBetween>

      {/* Custom Tags Manager */}
      <CustomTagsManager
        chipData={tags}
        setChipData={setTags}
        setEnglishTags={setEnglishTags}
      />

      {/* Snackbar */}
      <CustomSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Box>
  );
};

export default FeedEventForm;
