import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import WidgetWrapper from "../../components/WidgetWrapper";
import { setEvents } from "../../state/reactor";
import { createAxiosEvent } from "../../api/axios";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { validateNumericInputWeight } from "../../utils/validation";
import useAxiosInterceptor from "../../hooks/useAxiosIntercepter";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

// Event Forms
import {
  FeedEventForm,
  GenericEventForm,
  HarvestEventForm,
} from "../../components/events/forms";

const EditEventWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const axiosEvent = createAxiosEvent();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // States for form fields
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("feed");
  const [weight, setWeight] = useState("");
  const [recirculated, setRecirculated] = useState("");
  const [smell, setSmell] = useState("");
  const [liquidity, setLiquidity] = useState("");
  const [homogeneity, setHomogeneity] = useState("");
  const [tags, setTags] = useState([]);
  const [englishTags, setEnglishTags] = useState([]);
  const [errors, setErrors] = useState([]);
  const { eventId, reactorId } = useParams();
  const [eventTimestamp, setEventTimestamp] = useState("");

  const [smellDescription, setSmellDescription] = useState("");
  const [liquidityDescription, setLiquidityDescription] = useState("");
  const [homogeneityDescription, setHomogeneityDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const axiosEventClient = useAxiosInterceptor(axiosEvent);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("Feed");
  const [isFile, setIsFile] = useState(false);
  const [file, setFile] = useState("");

  const eventTypes = [
    { value: "feed", label: t('eventTypes.feed') },
    { value: "harvest", label:t('eventTypes.harvest') },
    { value: "generic", label: t('eventTypes.generic') },
  ];

  // Fetch event details and populate state
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axiosEvent.get(`/${reactorId}/${eventId}`);
        const event = response.data.data.event;
  
        setEventName(event.name || "");
        setEventType(event.event_type || "generic");
        setWeight(event.content?.weight || "");
        setRecirculated(event.content?.recirculated || "");
        setSmell(event.content?.smell?.value || "");
        setLiquidity(event.content?.liquidity?.value || "");
        setHomogeneity(event.content?.homogeneity?.value || "");
        setEventTimestamp(moment(event.created_at).utc().format('YYYY-MM-DD HH:mm') || "");
        setSelectedDateTime(moment(event.created_at).toDate() || "");
        setTags(event.content?.tags || []);
        setEnglishTags(event.content?.tags || []); 
      } catch (error) {
        console.error(t('errors.fetchingEventData'), error);
        setErrors(t('errors.fetchingEventData'));
      }
    };
  
      fetchEventDetails();
  }, [reactorId, eventId]);

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);
  };

  const handleEventSubmit = async () => {
    const content = {
      weight,
      recirculated,
      smell: { value: smell },
      liquidity: { value: liquidity },
      homogeneity: { value: homogeneity },
      tags: englishTags.map((tag) => tag.label),
    };

    const updatedEvent = {
      name: eventName,
      type_name: eventType,
      content,
    };

    try {
      const response = await axiosEvent.post(`/${reactorId}/${eventId}/edit`, updatedEvent);
      dispatch(setEvents(response.data.data));
    } catch (error) {
      console.error(t('errors.updatingEvent'), error);
    //   setErrors("Error editing event.");
      setErrors(error.response.data.data.error);
    }
  };

    const handleEvent = async () => {
      if (dateError) {
        setSnackbarMessage(dateError);
        setSnackbarOpen(true);
        return;
      }
      setShowDateTimePicker(false);
      setSelectedDateTime(new Date());
      let weightValidationErrors = "";
      const eventDateTimeUTC = selectedDateTime.toISOString();
      if (eventType !== "generic") {
        weightValidationErrors += validateNumericInputWeight({
          inputValue: weight,
          inputName: "Weight",
          minValue: 1,
          maxValue: 2000,
          t,
        });
      }
      if (eventType === "feed") {
        weightValidationErrors += validateNumericInputWeight({
          inputValue: recirculated,
          inputName: "Recirculated",
          minValue: 0,
          maxValue: 500,
          t,
        });
      }
      if (weightValidationErrors.length > 0) {
        setErrors(weightValidationErrors);
        setSnackbarMessage(weightValidationErrors);
        setSnackbarOpen(true);
        return;
      }
      const content = {};

      if (eventType === "feed") {
        content.recirculated = Number(recirculated);
        content.tags = englishTags.map(tag => tag.label);
      }

      if (eventType === "harvest") {
        content.smell = { value: smell, description: smellDescription };
        content.liquidity = { value: liquidity, description: liquidityDescription };
        content.homogeneity = { value: homogeneity, description: homogeneityDescription };
      }

      if (eventType !== "generic") {
        content.weight = Number(weight);
      }

      const event = {
        name: eventName,
        content: content,
        type_name: eventType,
      };

      const formData = new FormData();
      formData.append("name", eventName);
      formData.append("type_name", eventType);
      formData.append("content", JSON.stringify(content));

      if (showDateTimePicker) {
        formData.append("created_at", eventDateTimeUTC);
      }

      if (file) {
        formData.append("file", file);
      }

      try {
        const response = await axiosEventClient.post(
          `/${reactorId}/${eventId}/edit`,
          formData,
          {}
        );
        dispatch(setEvents(response.data.data));
        // Show success snackbar message
        setSnackbarMessage(t('successes.updateEvent'));
        setSnackbarOpen(true);

        // Navigate to the previous screen after a short delay
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch (error) {
        const errorMessage = t('errors.submittingEvent');
        setErrors(errorMessage);
        setSnackbarMessage(errorMessage);
        setSnackbarOpen(true);
        return;
      }
    };

  const handleDateTimeChange = (newValue) => {
    const now = new Date();
    if (newValue > now) {
      setDateError(t('errors.dateInFuture'));
    } else {
      setDateError("");
      setSelectedDateTime(newValue);
    }
  };

  const handleFileChange = (acceptedFiles) => {
    if (acceptedFiles === null) {
      setFile(null);
    } else if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const toggleIsFile = () => {
    setIsFile(!isFile);
  };

  const toggleDateTimePicker = () => {
    setShowDateTimePicker(!showDateTimePicker);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSmellChange = (value, description) => {
    setSmell(value);
    setSmellDescription(description);
  };

  const handleLiquidityChange = (value, description) => {
    setLiquidity(value);
    setLiquidityDescription(description);
  };

  const handleHomogeneityChange = (value, description) => {
    setHomogeneity(value);
    setHomogeneityDescription(description);
  };

  const handleEventDetailsChange = (newDetails) => {
    const { name, weight, recirculated, smell, smellDescription, liquidity, liquidityDescription, homogeneity, homogeneityDescription } = newDetails;
    setEventName(name);
    setWeight(weight);
    setRecirculated(recirculated);
    setSmell(smell);
    setSmellDescription(smellDescription);
    setLiquidity(liquidity);
    setLiquidityDescription(liquidityDescription);
    setHomogeneity(homogeneity);
    setHomogeneityDescription(homogeneityDescription);
  };

  return (
    <WidgetWrapper>
      <Typography variant="h6">{eventTimestamp}</Typography>
      <Box mb={2}>
        <Select
          value={eventType}
          onChange={handleEventTypeChange}
          displayEmpty
          inputProps={{ "aria-label": "Event Type" }}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          {eventTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Render forms based on the event type */}
      {eventType === "feed" && (
        <FeedEventForm
        feedName={eventName}
        weight={weight}
        recirculated={recirculated}
        onEventDetailsChange={handleEventDetailsChange}
        onFeedNameChange={(e) => {
          setEventName(e.target.value);
          handleEventDetailsChange({
            name: e.target.value,
            weight,
            recirculated,
          });
        }}
        onWeightChange={(e) => setWeight(e.target.value)}
        onRecirculatedChange={(e) => setRecirculated(e.target.value)}
        isFile={isFile}
        onFileChange={handleFileChange}
        file={file}
        setIsFile={toggleIsFile}
        showDateTimePicker={showDateTimePicker}
        selectedDateTime={selectedDateTime}
        onDateTimeChange={handleDateTimeChange}
        dateTimeError={dateError}
        onToggleDateTimePicker={toggleDateTimePicker}
        handleEvent={handleEvent}
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
        tags={tags}
        setTags={setTags}
        englishTags={englishTags}
        setEnglishTags={setEnglishTags}
        />
      )}

      {eventType === "harvest" && (
        <HarvestEventForm
        harvestName={eventName}
        weight={weight}
        smell={smell}
        liquidity={liquidity}
        homogeneity={homogeneity}
        onEventDetailsChange={handleEventDetailsChange}
        onHarvestNameChange={(e) => {
          setEventName(e.target.value);
          handleEventDetailsChange({
            name: e.target.value,
            weight,
            smell,
            smellDescription,
            liquidity,
            liquidityDescription,
            homogeneity,
            homogeneityDescription,
          });
        }}
        onWeightChange={(e) => setWeight(e.target.value)}
        onSmellChange={handleSmellChange}
        onLiquidityChange={handleLiquidityChange}
        onHomogeneityChange={handleHomogeneityChange}
        isFile={isFile}
        onFileChange={handleFileChange}
        file={file}
        setIsFile={toggleIsFile}
        showDateTimePicker={showDateTimePicker}
        selectedDateTime={selectedDateTime}
        onDateTimeChange={handleDateTimeChange}
        dateTimeError={dateError}
        onToggleDateTimePicker={toggleDateTimePicker}
        handleEvent={handleEvent}
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
        />
      )}

      {eventType === "generic" && (
        <GenericEventForm
        genericEventName={eventName}
        onEventDetailsChange={handleEventDetailsChange}
        onGenericEventNameChange={(e) => {
          setEventName(e.target.value);
          handleEventDetailsChange({
            name: e.target.value,
          });
        }}
        isFile={isFile}
        onFileChange={handleFileChange}
        file={file}
        setIsFile={toggleIsFile}
        showDateTimePicker={showDateTimePicker}
        selectedDateTime={selectedDateTime}
        onDateTimeChange={handleDateTimeChange}
        dateTimeError={dateError}
        onToggleDateTimePicker={toggleDateTimePicker}
        handleEvent={handleEvent}
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
        />
      )}

      {errors && <Typography color="error">{errors}</Typography>}
    </WidgetWrapper>
  );
};

export default EditEventWidget;
