import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import MissingDataEventsWidget from "../widgets/MissingDataEventsWidget";
import useOnlineStatus from "../../context/useOnlineStatus";
import { tokens } from "../../theme";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router"
import { setReactor } from "../../state/auth";
import Header from "../../components/Header";
import reactor from "../../state/reactor";

const MissingData = () => {
  const { t } = useTranslation();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const id = useSelector((state) => state.auth.user_id);
  const reactorId = useSelector((state) => state.auth.reactorId);
  const { reactorId: urlReactorId } = useParams();
  const isOnline = useOnlineStatus();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (urlReactorId) {
      // Set the reactorId in Redux state
      dispatch(setReactor({
        reactorId: urlReactorId,
      }));
    }
  }, [urlReactorId, dispatch]);

  return (
    <Box>
      <Header
        title={t('missingData.title')}
        subtitle={t('missingData.subtitle')}
      />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Typography
          color={colors.primary[300]}
          sx={{fontSize: 28,
        }}
        >
          {isOnline ? "" : t('reactor.offlineWarning')}
        </Typography>
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MissingDataEventsWidget userId={id} key={reactorId} reactorId={reactorId} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="2rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MissingData;
