import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../state/reactor";
import EventWidget from "./EventWidget";
import { createAxiosPrivate } from "../../api/axios";
import MissingDataEventWidget from "./MissingDataEventWidget";
import { useTranslation } from 'react-i18next';


const MissingDataEventsWidget = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const events = useSelector((state) => state.reactor.events);
  const reactorId = useSelector((state) => state.auth.reactorId);
  const endpoint = `/events/${reactorId}/missing`;
  const axiosPrivate = createAxiosPrivate();

  useEffect(() => {
    const getData = async () => {
        try {
          const response = await axiosPrivate.get(endpoint);
          dispatch(setEvents(response.data.data));
        } catch (err) {
          console.error(err);
        }
      }
    getData();

  }, []);

  if (events.length === 0) {
    return <div>{t('missingData.noEvents')}</div>;
  }

  return (
    <>
      {events.map(
        ({
          id,
          user_id,
          username,
          name,
          event_type,
          type_id,
          content,
          description,
          location,
          created_at,
          likes,
          comments,
          files,
        }) => (
          <MissingDataEventWidget
            key={id}
            reactorId={reactorId}
            eventId={id}
            eventUserId={user_id}
            eventUserName={username}
            name={name}
            type_name={event_type.name}
            type_id={type_id}
            content={content}
            description={description}
            location={location}
            created_at={created_at}
            likes={likes}
            comments={comments}
            files={files}
          />
        )
      )}
    </>
  );
};

export default MissingDataEventsWidget;
