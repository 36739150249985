import Login from "./components/Login";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Home from "./scenes/home";
import { Routes, Route, useLocation } from "react-router-dom";
import { Reset } from "./components/ResetPassword";
import { RequestReset } from "./components/RequestReset";
import { useState, useEffect } from "react";
import Topbar from "./scenes/global/Topbar";
import BottomBar from "./scenes/global/Bottombar";
import Reactor from "./scenes/reactor";
import ReactorPicker from "./components/ReactorPicker";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import ProfilePage from "./scenes/profilepage";
import Colleagues from "./scenes/colleagues";
import IndepthEvent from "./scenes/detailed_event";
import Alerts from "./scenes/alerts";
import Settings from "./scenes/settings";
import Notifications from "./scenes/notiications";
import Account from "./scenes/account";
import { useSetLanguage } from "./localisation/useSetLanguage";
import { getSocket } from "./api/socket";
import EntryReactor from "./components/EntryReactor";
import MissingData from "./scenes/missing_data";
import EditEventWidget from "./scenes/widgets/EditEventWidget";


const ROLES = {
  Operator: "operator",
  Admin: "admin",
};

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();


  const hiddenRoutes = ["/login", "/reset", "/confirm", "/request_reset"];

  const renderTopbarAndBottomBar = !hiddenRoutes.includes(location.pathname);

  // Fetch users language settings
  useSetLanguage();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            {renderTopbarAndBottomBar && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="login" element={<Login />} />
              <Route
                path="reset"
                element={<Reset endpoint="/user/reset_finalize" />}
              />
              <Route path="confirm" element={<Reset endpoint="/user/finalize" />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="request_reset" element={<RequestReset />} />

              <Route element={<PersistLogin />}>
                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.Operator, ROLES.Admin]} />
                  }
                >
                  <Route path="/" element={ <Home />} />
                  <Route path="/reactor_picker" element={<ReactorPicker />} />
                  <Route path="/reactor/:reactorId" element={<Reactor />} />
                  <Route path="/account" element={<Account/>} />
                  <Route path="/colleagues" element={<Colleagues />} />
                  <Route path="/event/:eventId" element={<IndepthEvent />} />
                  <Route path="/profile/:userId" element={ <ProfilePage />} />
                  <Route path="/alerts" element={ <Alerts />} />
                  <Route path="/settings" element={ <Settings />} />
                  <Route path="/notifications" element={ <Notifications />} />
                  <Route path="/missing-data/:reactorId" element={<MissingData />} />
                  <Route path="/missing-data/event/:reactorId/:eventId" element={<EditEventWidget />} />
                </Route>
              </Route>

              {/* Catch all */}
              <Route path="*" element={<Missing />} />
            </Routes>

            {renderTopbarAndBottomBar && <BottomBar isSidebar={isSidebar} />}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
